import React, { useRef } from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'components/common/utils/Image';
import { Layout } from 'components/common';
import Row from 'react-bootstrap/Row';
import Section from 'components/common/Section';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import Cta from 'components/common/Cta';
import TestimonySection from 'components/TestimonySection';
import { useAnchorEffect, scrollToAnchor } from "components/common/CharlesLayout/useAnchorEffect";
import AnchorMenu from 'templates/Pathologies/common/AnchorMenu';
import './styles.sass';

const anchors = [
  { id: 'hiw-consultation', label: 'Consultation à distance' },
  { id: 'hiw-treatments', label: 'Traitement personnalisé' },
  { id: 'hiw-follow-up', label: 'Suivi médical' },
];

const IndexPage = () => {
  const anchorLinksContainerRef = useRef();
  const anchorLinksRefs = useRef({});

  const [scrollStatus, setScrollStatus] = useAnchorEffect(anchorLinksContainerRef, anchorLinksRefs, 'hiw-anchor')
  const title = "Comment ça marche ?"
  const description = "Renseignez votre profil médical. Téléconsultez un médecin pour le sommeil, la digestion, l'alopécie ou les troubles sexuels et obtenez un traitement, livraison en 48h."
  return (
    <Layout
      showLinksInFooter={false}
    >
      <CharlesMeta
        data={{
          title: 'Comment consulter et obtenir un traitement | Charles.co',
          description: description
        }}
      />
      <StructuredWebPage
        title={title}
        description={description}
      />
      <div id="hiw-page" className="hiw-main-div">
        <Section className="hiw-top-section">
          <Container className="d-flex flex-column align-items-center">
            <h1 className="">{title}</h1>
            <div className="hiw-yellow-entourage">
              <Image
                filename="new-images/yellow-pen-entourage.svg"
                className=""
                title="yellow pen entourage"
                alt="yellow-pen-entourage"
              />
            </div>
            <div className="hiw-top-section-description">
              Chez Charles, nous vous accompagnons tout au long de votre parcours de soin : de l'identification du problème jusqu'à sa résolution. Grâce à la consultation avec un médecin spécialiste, vous accèderez à un plan de traitement adapté et un suivi médical permanent.
            </div>
          </Container>
        </Section>
        <AnchorMenu
          anchors={anchors}
          scrollingUp={scrollStatus.scrollingUp}
          hoveredAnchorId={scrollStatus.hoveredAnchorId}
          anchorLinksRefs={anchorLinksRefs}
          anchorLinksContainerRef={anchorLinksContainerRef}
          scrollOffset={0}
        />
        <Section className="hiw-question-section">
          <div id="hiw-consultation" className="hiw-consultation-class hiw-anchor" />
          <Container>
            <Row className="hiw-question-section-row">
              <Col xs={12} md={8} xl={6} className="align-self-center">
                <div className="hiw-question-section-image-card">
                  <Image
                    className="hiw-question-section-image"
                    alt="teleconsulter-un-specialiste-a-distance"
                    title="teleconsulter un specialiste a distance"
                    filename="new-images/iphone-mockup-1.png"
                  />
                </div>
              </Col>
              <Col xs={12} md={8} xl={5}>
                <div className="hiw-question-section-text-card">
                  <h2>1. Consultez à distance un médecin spécialiste</h2>
                  <div className="hiw-question-section-desc">
                    Détaillez vos symptômes dans un questionnaire et consultez à
                    distance avec un de nos médecins spécialistes, où vous
                    voulez et quand vous voulez par vidéo, téléphone ou messages
                    écrits. Vos données de santé ne sont accessibles que par
                    votre médecin et sécurisées par notre hébergeur agréé en données de santé. Nous personnalisons les téléconsultations pour
                    chaque pathologie traitée et chaque patient.
                  </div>
                  <div>
                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/doctor-talking.svg"
                        alt="docteur-qui-parle"
                        title="docteur qui parle"
                      />
                      <div>Spécialistes français diplômés</div>
                    </div>
                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/icon-small-economies-square.svg"
                        alt="donnees-protegees-icon"
                        title="donnees protegees icon"
                      />
                      <div>35€ au lieu de 80€ en moyenne en cabinet</div>
                    </div>
                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/icon-med.svg"
                        alt="donnees-protegees-icon"
                        title="donnees protegees icon"
                      />
                      <div>Échanges confidentiels et sécurisés</div>
                    </div>
                  </div>
                  <Cta
                    path="/consulter/"
                    className="new-primary-cta hiw-question-section-cta"
                    text="Consultez un médecin"
                  />
                </div>
              </Col>
            </Row>
            <span id="hiw-treatments" className="hiw-treatments-class hiw-anchor" />
            <Row className="hiw-question-section-row">
              <Col xs={12} md={8} xl={6}>
                <div className="hiw-question-section-image-card">
                  <Image
                    className="hiw-question-section-image"
                    alt="Boite-de-médicaments"
                    title="Boite de médicament"
                    filename="new-images/mockup-charles-drugs.png"
                  />
                </div>
              </Col>
              <Col xs={12} md={8} xl={5}>
                <div className="hiw-question-section-text-card">
                  <h2>2. Recevez un traitement personnalisé</h2>
                  <div className="hiw-question-section-desc">
                    À la suite de la
                    téléconsultation, le médecin vous propose un plan de
                    traitement personnalisé. Ce plan de traitement comprendra
                    les solutions thérapeutiques les plus adaptées à votre
                    situation : Livraison de médicaments à domicile depuis des
                    pharmacies françaises, phytothérapies, thérapies
                    digitales... Ce plan de traitement vise à être la réponse la
                    plus complète pour vous accompagner dans la résolution de
                    votre problème.
                  </div>
                  <div>
                    <div className="hiw-question-section-reinsurance">
                      <Image filename="new-images/tick-list.svg"
                        alt="check-list"
                        title="check list" />
                      <div>Plan de traitements complets et personnalisés</div>
                    </div>

                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/package.svg"
                        alt="package-picto"
                        title="package picto"
                      />
                      <div>Livraison discrète à domicile</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <span id="hiw-follow-up" className="hiw-follow-up-class hiw-anchor" />
            <Row className="hiw-question-section-row">
              <Col xs={12} md={8} xl={6}>
                <div className="hiw-question-section-image-card">
                  <Image
                    className="hiw-question-section-image"
                    alt="suivi-assistance-medicale-xl"
                    title="suivi assistance medicale xl"
                    filename="new-images/iphone-mockup-2-xl.png"
                  />
                </div>
              </Col>
              <Col xs={12} md={8} xl={5}>
                <div className="hiw-question-section-text-card">
                  <h2>3. Bénéficiez d'un suivi médical</h2>
                  <div className="hiw-question-section-desc">
                    {/* eslint-disable-next-line max-len */}
                    Chacun de nos membres bénéficie d'une assistance médicale
                    gratuite tout au long de son plan de traitement. Nous
                    l'accompagnons 5 jours sur 7 de façon illimitée (assistance
                    thérapeutique, ajustement de traitement) par téléphone ou
                    messages. Chaque patient est unique et reçoit un
                    accompagnement personnalisé.
                  </div>
                  <div>
                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/doctor-talking.svg"
                        alt="docteur-qui-parle-2"
                        title="docteur qui parle 2"
                      />
                      <div>Assistance illimitée gratuite</div>
                    </div>
                    <div className="hiw-question-section-reinsurance">
                      <Image
                        filename="new-images/phone-sparkling.svg"
                        alt="mobile-sintillant-picto"
                        title="mobile sintillant picto"
                      />
                      <div>5 jours sur 7 par téléphone ou message</div>
                    </div>
                  </div>
                  <Cta
                    path="/consulter/"
                    className="new-primary-cta hiw-question-section-cta"
                    text="Consultez un médecin"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <TestimonySection className="hiw-testimony-section" />
      </div>
    </Layout>
  );
};
export default IndexPage;
