import { TestimonyCarouselItemProps } from 'components/common/TestimonyCarousel/index'

export const homeTestimonies: TestimonyCarouselItemProps[] = [
  {
    text:
      "Je dois dire que je galère dans ma vie sexuelle depuis cinq ans en ne comprenant pas ce qui se passe, et surtout en ne sachant pas comment m'en sortir. Vous avez su identifier mes besoins très efficacement. Je vous tiendrai informé de la suite des opérations. C'est à moi de jouer, mais vous avez su me donner la feuille de route. Merci infiniment.",
    info: "Marc, 42 ans",
  },
  {
    text:
      "C'est la première fois que je me suis sentie entendue vis à vis de mes problèmes de sommeil et surtout j'ai pu bénéficier d’une thérapie individualisée qui m'a énormément aidée ! Aujourd'hui j'arrive à mieux dormir et je revis ! Je recommande la thérapie digitale Sommeil de Charles et de faire sérieusement les exercices recommandés.",
    info: "Esther, 28 ans",
  },
  {
    text:
      "La consultation m'a permis de bien comprendre les avantages et les inconvénients de chaque traitement et les premiers résultats sont là.",
    info: "Olivier, 34 ans",
  },
  {
    text:
      "Cela faisait plus de 10 ans que je souffrais de douleurs de ventre à type de crampes et de colites avec certains jours de forte constipation et d'autres jours des diarrhées sans trouver de solutions avec mon médecin traitant. Grâce aux médecins de Charles à présent  je revis ! Ils ont trouvé d'où venait le problème : je n’ai plus mal au ventre . j’ai pu reprendre une vie \"normale.",
    info: "Cloé, 45 ans",
  },
];
